import React from 'react';

import Header from '../components/_organisms/Header/Header';
import Footer from '../components/_organisms/Footer/Footer';

import './index.scss';

const Layout = ({ children }) => (
    <div className="blog">
        <Header />
        {children}
        <Footer />
    </div>
);

export default Layout;
