import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../environment/logos/Logo.svg';
import Glyph from '../../environment/logos/Glyph.svg';

import './header.scss';

const Header = () => (
    <header className="header">
        <div className="wrapper">
            <a href="https://orton.io" className="logo-container">
                <img src={Logo} alt="Orton Logo" className="logo" />
                <img src={Glyph} alt="Orton Logo" className="glyph" />
            </a>
            <a href="https://orton.io/login" className="sign-in button button--filled">
                Sign in
            </a>
        </div>
    </header>
);

export default Header;
