import React from 'react';
import Helmet from 'react-helmet';

import favicon16 from '../../environment/favicon/favicon-16x16.png';
import favicon32 from '../../environment/favicon/favicon-32x32.png';
import LinkImage from '../../environment/logos/LinkImage.png';

const SEO = ({ title, description, author, image }) => (
    <Helmet
        link={[
            { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
            { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        ]}
    >
        <html lang="en" />
        <title>{title ? title : "Orton Blog - Where writers become authors"}</title>
        <meta name="description" content={description ? description : "Orton blog. Where we share our progress with our users and help deliver support for users to succeed on our platform."} />
        <meta name="author" content={author ? author : 'Orton'} />
        <meta property="og:image" content={image || 'https://blog.orton.io/static/LinkImage-0d9ff3b57c4d80c15298ac1c8b472968.png' || LinkImage} />
        <meta property="og:image:type" content="image/png" />
    </Helmet>
);

export default SEO;