import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../environment/logos/LogoWhite.svg';

import './footer.scss';

const Footer = () => (
    <footer className="footer">
        <div className="wrapper">
            <img className="logo" src={Logo} alt="Orton logo" />
            <p className="rights">© 2019 Orton Publishing Ltd. All Rights Reserved.</p>
            <ul className="footer-links">
                <li className="footer-link"><Link to="/orton-audio">Audiobooks</Link></li>
                <li className="footer-link"><Link to="/about">About</Link></li>
                <li className="footer-link"><Link to="/faq">FAQs</Link></li>
                <li className="footer-link"><a href="https://www.iubenda.com/privacy-policy/7821898" target="_blank" rel="noopener noreferrer">Privacy policy</a></li>
                <li className="footer-link"><Link to="/terms-and-conditions">Terms of service</Link></li>
                <li className="footer-link"><a href="https://twitter.com/OrtonPublishing" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                <li className="footer-link"><a href="https://www.facebook.com/ortonwriter/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                <li className="footer-link"><a href="https://blog.orton.io/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                <li className="footer-link"><Link to="/press-kit">Press kit</Link></li>
            </ul>
            <a href="https://heapanalytics.com/?utm_source=badge" rel="nofollow" className="heap">
                <img style={{ width: '108px', height: '41px' }} src="//heapanalytics.com/img/badgeLight.png" alt="Heap | Mobile and Web Analytics" />
            </a>
        </div>
    </footer>
);

export default Footer;
